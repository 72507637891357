<template>
  <div class="box">
    <img src="@/assets/images/logo-transparent.png" class="mb-5 w-100" alt="">
    <p class="text-center text-success">أدخل الرمز السري</p>
    <div id="otp" class="mt-2">
      <input v-model="pin1" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin2" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin3" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin4" type="text" v-on:keyup="keyMonitor" maxlength="1" />
      <input v-model="pin5" type="text" v-on:keyup="keyMonitor" maxlength="1" />
    </div>

    <div class="mt-4 butn">
      <button :class="{game_btn:true,activeBtn:isChanged}" :disabled="btnDisabled"  style="direction: rtl" id="sub_btn" @click="verify()">{{ btnDisabled ? "تحميل ..." : "أشترك"}}</button>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api'
import AlertServie from '../../services/errors'
import Vue from 'vue'
export default {
  data () {
    return {
      prefixPHone: '+964',
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      pin5: "",
      phone: '+964',
      isChanged: false,
      btnDisabled: false
    }
  },
  methods: {
    keyMonitor (event) {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        event.target.previousElementSibling.focus()
      } else {
        this.isChanged = true
        event.target.nextElementSibling.focus()
      }
    },
    async verify () {
      this.btnDisabled = true
      const otp = this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5
      const phoneNumber = this.$store.state.phoneNumber

      const response = await ApiService.verify(this.$route.params.phoneNumber, otp, this.$route.params.referenceId)
      const self = this

      if (response.data.data && (response.data.code === 0 || response.data.code === 22 || response.data.code === 123)) {
        if (response.data.data !== "") {
          localStorage.setItem("fitVibToken", response.data.data.access_token)
          ApiService.headers = {
            Authorization: "Bearer " + response.data.data.access_token,
            lang: localStorage.getItem("lang")
          }
          self.$router.push(response.data.code === 0 || response.data.code === 123 ? '/thank-you' : '/')
        } else {
          AlertServie.info(response.data.messsage)

          if (response.status === 200) {
            this.checkUserStatusInterval = setInterval(async function () {
              const response = await ApiService.checkUserStatus(phoneNumber)
              if (response && response.status === 200) {
                if (response.data.status === 2 || response.data.status === 1) {
                  localStorage.setItem("fitVibToken", response.data.data.access_token)
                  clearInterval(self.checkUserStatusInterval)
                  ApiService.headers = {
                    Authorization: "Bearer " + response.data.data.access_token,
                    lang: localStorage.getItem("lang")
                  }
                  self.$router.push('/')
                  Vue.$forceUpdate()
                } else {
                  if (response.data.status === 3) {
                    clearInterval(self.checkUserStatusInterval)
                  }
                  AlertServie.info(response.data.message)
                  this.disabled = false
                  this.btnDisabled = false
                }
              } else {
                clearInterval(self.checkUserStatusInterval)
                AlertServie.info(response.data.message)
                this.btnDisabled = false
                this.disabled = false
              }
            }, 5000)
          }
        }
      } else {
        clearInterval(self.checkUserStatusInterval)
        this.disabled = false
        this.btnDisabled = false
      }
    }
  }
}
</script>
